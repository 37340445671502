.game-roulette-wheel {
    width: 35%;
    height: 45%;
    padding: 3%;
    background: #d5d5d5aa;
}

.round-bet-closed {
    display: flex;
    justify-content: center;
    color: red;
    font-size: 20px;
    font-weight: bold;

}

@media screen and (max-width: 800px) {
    .round-bet-closed {
        font-size: 15px;
    }
}


@media screen and (max-width: 530px) {
    .round-bet-closed {
        font-size: 10px;
    }
}


@media screen and (max-width: 350px) {
    .round-bet-closed {
        font-size: 8px;
    }
}

.last-win-modal.ant-modal{
    width: 700px !important;
}

.last-win-container-bg {
    width: 640px;
    height: 320px;
    overflow-y: scroll;
    padding: 12px;
    background-color: white;
}

.last-win-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: flex-start;
    width: 100%;
}

.last-win-box {
    margin: 5px 5px;
    color: white;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35%;

    font-size: 15pt;
    font-weight: bold;
}

@media screen and (max-width: 800px) {
    .last-win-modal.ant-modal{
        width: 550px !important;
    }

    .last-win-container-bg {
        width: 523px;
        height: 225px;
        padding: 10px;
    }

    .last-win-box {
        margin: 4px 4px;
        width: 40px;
        height: 40px;
        font-size: 8pt;
    }
}


@media screen and (max-width: 595px) {
    .last-win-modal.ant-modal{
        width: 450px !important;
    }

    .last-win-container-bg {
        width: 400px;
        height: 120px;
        padding: 5px;
    }

    .last-win-box {
        margin: 2.5px 2.5px;
        width: 31px;
        height: 31px;
        font-size: 8pt;
    }
}

@media screen and (max-width: 515px) {
    .last-win-modal.ant-modal{
        width: 340px !important;
    }

    .last-win-container-bg {
        width: 300px;
        height: 120px;
        padding: 5px;
    }

    .last-win-box {
        margin: 2.5px 2.5px;
        width: 41px;
        height: 41px;
        font-size: 8pt;
    }
}

@media screen and (max-width: 350px) {
    .last-win-modal.ant-modal{
        width: 80% !important;
    }

    .last-win-container-bg {
        width: 90% !important;
        height: 120px;
        padding: 5px;
    }

    .last-win-box {
        margin: 2.5px 2.5px;
        width: 41px;
        height: 41px;
        font-size: 8pt;
    }
}


@media screen and (max-width: 350px) {
    .round-bet-closed {
        font-size: 8px;
    }
}


.black-box {
    background-color: black;
}

.red-box {
    background-color: red;
}

.green-box {
    background-color: green;
}