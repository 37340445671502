.admin-user-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.admin-user-button-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 15px 15px;
}

@media screen and (max-width: 630px) {
    .admin-user-buttons {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
}
