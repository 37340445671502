.summary {
    display: flex;
    flex-direction: row;
    width: 100vw;
}
.summary span {
    color: white;
    font-size: 9pt;
    font-weight: bold;
}
.summary-box {
    padding: 0px 20px;
}

.summary-box > input[type="number"] {
    width: 75px;
}