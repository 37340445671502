* {
  margin: 0px;
  padding: 0px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #034c1c;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.game-container {
  display: flex;
  flex-direction: row;
}
.game-left-side {
  flex: 8;
}
.game-right-side {
  flex: 1;
  max-height: 40vw;
}

.toast-message   {
  width: 20vw !important;
  font-size: x-small;
  font-weight: 700;
}

/* Start Table */
.active-table-row {
  background: rgba(109, 231, 111, 0.7);
}

.active-table-row:hover {
  background: transparent;
}

.active-table-row:hover .ant-table-cell-row-hover {
  background: rgba(109, 231, 111, 0.5) !important;
}

.closed-table-row {
  background-color: rgba(250, 34, 42, 0.7);
}

.closed-table-row:hover {
  background: transparent;
}

.closed-table-row:hover .ant-table-cell-row-hover {
  background: rgba(250, 34, 42, 0.5) !important;
}
/* End Table */