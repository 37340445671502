.active-bet {

}

.cancelled-bet {
    background-color: #A70E1355;
}

.completed-bet {
    background-color: #2d964955;
}