.st0 {
    fill: #fb4444;
}

.st1 {
    fill: #664F2D;
}

.st2 {
    fill: #2d9649;
}

#wheel {
    animation: spin 6s infinite linear reverse;
    transform-origin: center;
}

#ball {
    animation: spin 3s infinite linear;
    transform-origin: center;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.svg-style {
    enable-background:new 0 0 442.5 184.667;
}