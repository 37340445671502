.chip-box {
    cursor: pointer;
    margin: 10px 0;
    border-radius: 15%;

    padding-top: 5%;
    padding-left: 20%;
}

.chip-box:hover {
    background-color: #d5d5d550;
}

.chip-box svg {
    height: 70%;
    max-height: 8vw;
}

.chip-box-3 svg {
    max-height: 9vw;
}

.chip-box-4 svg {
    max-height: 8vw;
}

.chip-box-5 svg {
    max-height: 6vw;
}

.chip-box-6 svg {
    max-height: 5vw;
}

.chip-box.selected-chip {

    cursor: default;
}

.chip-box.selected-chip:hover {
    background-color: #d5d5d580;
}

.selected-chip {
    background-color: #d5d5d550;
}
.chip-box path{ opacity: .60;}
.selected-chip path,
.chip-box:hover  path
{
    opacity: 1 !important;
}
