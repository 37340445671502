.chip1{fill:#cbaad7;}
.chip2{fill:#A70E13;}
.chip3{fill:#005599;}
.chip4{fill:#ffe994;}

rect {
    fill: transparent;
    stroke: none;
}

.bet-box:hover {
    fill: #f5f5f545;
}

.container {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.table {
    width: 100%;
    position: relative;
    display: inline-block;
}

.table svg {
    position: absolute;
    top: 0;
    left: 0;
    aspect-ratio: 2;
}

.winner-bet {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
    animation-name: WINNER_ANIMATION;

}

@keyframes WINNER_ANIMATION {
    to {
        stroke: #cfd220;
        stroke-width: 20px;
        fill: #cfd22075;
    }
}